import { Drawer } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { logo } from "../../../assets/images/imageComponents";
import DashboardUserProfile from "../../components/userProfile/dashboard/dashboardUserProfile";
import TransactionUserProfile from "../../components/userProfile/transactions/transactionsUserProfile";
import UserComponent from "../../components/userProfile/userComponent";
import WalletUserProfile from "../../components/userProfile/wallet/walletUserProfile";
import LeftSide from "./leftSide";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const [menu, setVisible] = useState(false);
  const [userProfile, setUserProfile] = useState(false);

  const openMenu = () => {
    setVisible(true);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  const openUserProfile = () => {
    setUserProfile(true);
  };

  const closeUserProfile = () => {
    setUserProfile(false);
  };

  return (
    <>
      <div className="wrapper">
        <div className="d-flex lg_hide xl_hide container-fluid py-3 align-items-center justify-content-between">
          <button
            className="bg-transparent d-flex py-1 align-items-center border"
            onClick={openMenu}
          >
            <span className="material-icons">menu</span>
          </button>
          <img src={logo} height="40px" alt="" />
          <button
            className="bg-transparent d-flex py-1 align-items-center border"
            onClick={openUserProfile}
          >
            <span className="material-icons">apps</span>
          </button>
        </div>
        <div className="d-flex vh-100 vw-100">
          <div className="xs_hide sm_hide md_hide">
            <LeftSide />
          </div>
          <div className="content scroll_y">{children}</div>
        </div>

        {/* Menu Drawer */}
        <Drawer
          closable={false}
          placement="left"
          width={180}
          onClose={closeMenu}
          visible={menu}
        >
          <LeftSide />
        </Drawer>

        {/* User Profile Drawer */}
        <Drawer
          closable={false}
          placement="right"
          width={300}
          onClose={closeUserProfile}
          visible={userProfile}
        >
          <div className="scroll_y vh-100">
            <UserComponent>
              {location.pathname === "/wallet" && <WalletUserProfile />}
              {location.pathname === "/dashboard" && <DashboardUserProfile />}
              {location.pathname === "/transactions/wallet" && (
                <TransactionUserProfile />
              )}
              {location.pathname === "/transactions/card" && (
                <TransactionUserProfile />
              )}
            </UserComponent>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default DashboardLayout;
