import { useState } from "react";
import { image_1, image_2, mobile1, mobile2, seamless } from "../../assets/images/imageComponents";
import WebsiteLayout from "../../shared/layouts/websiteLayout";
import Testimonial from "../../shared/components/testimonial";
import { api } from "../../shared/helpers/config";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { axiosConfig, url } from "../../shared/config";
import axios from "axios";

const Homepage = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation((formData) => {
    return axios.post(`${url}/${api.subscribe}`, formData, axiosConfig);
  });

  const subscribe = async (data) => {
    data.preventDefault();
    const formData = new FormData();
    formData.append("email", email);

    try {
      const data = await mutation.mutateAsync(formData);
      data.data.status && setEmail("");

      await enqueueSnackbar(data.data.msg, {
        variant: data.data.status ? "success" : "error",
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <WebsiteLayout>
      <div className="container my-4">
        <div className="row">
          <div className="col-md-9 col-lg-6 mb-4 d-flex align-items-center">
            <div>
              <h4 className="home_title">Swift ways to move and manage your money</h4>
              <p className="home_text my-4 my-md-5 col-lg-10">
                Swiftwaze is a swift, simple and modern way to send and receive money within Africa and across borders,
                send data, gift cards and airtime to your loved ones and open a USD account from your location in
                Africa.
              </p>
              <form className="d-flex align-items-center col-lg-10 mb-5" onSubmit={subscribe}>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="join_field w-100 me-3 ps-3"
                  placeholder="Join our waitlist now"
                />
                <button type="submit" className="join_btn">
                  {mutation.isLoading ? (
                    <span class="spinner-grow spinner-grow" role="status" aria-hidden="true"></span>
                  ) : (
                    "Join"
                  )}
                </button>
              </form>
              <div className="d-flex align-items-center my-4">
                <p className="mb-0 licensed_text">Licensed by The Central Bank of Nigeria</p>
              </div>
            </div>
          </div>
          <div className="col-md-8 xs_hide sm_hide col-lg-6 ms-md-auto d-flex justify-content-center">
            <div className="d-flex align-items-end justify-content-center">
              <img className="mobile_1 w-100" src={mobile1} alt="" />
              <img className="mobile_2 w-100" src={mobile2} alt="" />
            </div>
          </div>
        </div>
        <div className="row section_margin">
          <div className="col-md-6 mb-4 col-lg-6 xs_hide sm_hide d-flex align-items-center">
            <div className="seamless_pane ">
              <img className="w-100" src={seamless} alt="" />
            </div>
          </div>
          <div className="col-md-9 ms-md-auto col-lg-6 d-flex align-items-center">
            <div className="">
              <h4 className="home_sub_title my-3">Instantly Send and receive money hassle-free</h4>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="mb-3">Receive money without a bank account</h5>
                  <p className="home_text">
                    Swiftwaze allows you to send money home even if your loved ones don’t have bank accounts. All they
                    need is their phone number and unique code sent to them, no ID, just peace.
                  </p>
                  <br /> <br />
                  <h5 className="mb-3">Free transfers across wallets</h5>
                  <p className="home_text">
                    Using your Swiftwaze wallet, you can easily send and receive money free to other Swiftwaze wallet
                    holders.
                  </p>
                </div>
                <div className="col-md-6">
                  <h5 className="mb-3">Receive money directly to your Swiftwaze wallet or bank accounts</h5>
                  <p className="home_text">
                    You can receive money directly into your bank account or Swiftwaze wallet local currency or dollar
                    wallet.
                  </p>
                  <br /> <br />
                  <h5 className="mb-3">Payment Links</h5>
                  <p className="home_text">
                    Swiftwaze enables you to receive and request payments by sending your payment link to the sender
                    without having to send your bank account details.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row section_margin">
          <div className="col-md-9 col-lg-6 mb-4 d-flex align-items-center">
            <div>
              <div className="">
                <h4 className="home_sub_title my-3">Own a Dollar account from any location in Africa</h4>
                <p className="home_text">
                  Swiftwaze allows you to receive and save your money against inflation by saving in your secure Dollar
                  wallet. You can withdraw to your local currency wallet or bank account at anytime.
                </p>
              </div>
              <div className="mt-5">
                <h4 className="home_sub_title my-3">
                  Buy Airtime, Data and Pay Bills Internationally at the best Rates
                </h4>
                <p className="home_text">
                  Buy airtime and data for yourself or loved ones in Africa from overseas, recharge your cable TV
                  subscription, pay your electricity bills and fund your betting wallet. International airtime and data
                  recharge is available in over 70 countries.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-auto d-flex align-items-center">
            <div className="seamless_pane mx-auto" style={{ background: "#525298", borderRadius: "30px" }}>
              <img className="seamless_img w-100 image_1_border" src={image_1} alt="" />
            </div>
          </div>
        </div>
        <div className="row section_margin">
          <div className="col-md-6 col-lg-6 mb-4 d-flex align-items-center">
            <div className="seamless_pane">
              <img className="w-100" src={image_2} alt="" />
            </div>
          </div>
          <div className="col-md-9 ms-auto col-lg-6 d-flex align-items-center">
            <div className="">
              <h4 className="home_sub_title my-3">Why Swiftwaze</h4>
              <div className="row my-4">
                <div className="col-md-6 mb-3">
                  <h5 className="mb-3">Transparency and flexible pricing</h5>
                  <p className="home_text">
                    Our charges are transparent with no hidden or maintenance fees. And we offer the best rates for
                    cross border remittances, and recharge and international top up. What you see is what you get
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h5 className="mb-3">Fraud protection and Data security</h5>
                  <p className="home_text">
                    We use the highest level of encryption to protect your personal information including your identity
                    and confidential account details. We don’t share your information with unauthorized third parties.
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h5 className="mb-3">Recurring payments</h5>
                  <p className="home_text">
                    Set recurring payments and have money set automatically to your loved ones overseas
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h5 className="mb-3">24/7 Customer Support</h5>
                  <p className="home_text">
                    Our customer support team is empowered to go out of their way to resolve any issues you might have
                    around the clock.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="row">
          <div className="col-12 onboarding_bg">
            <div className="container">
              <h4 className="home_sub_title pb-5 text-center col-lg-6 mx-auto">
                How to send money/make payments within Africa or across borders in three simple steps
              </h4>
              <div className="row mt-4">
                <div className="col-md-4 mb-4">
                  <p className="home_text fw-bold col-lg-10 mx-auto">1. Register in minutes</p>
                  <p className="home_text mt-3 col-lg-10 mx-auto">
                    Download the app or Register a free account on the website in minutes and complete our KYC
                    verification.
                  </p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="home_text fw-bold col-lg-10 mx-auto">2. Fund Account</p>
                  <p className="home_text mt-3 col-lg-10 mx-auto">
                    Fund your Swiftwaze wallet from your bank account/or pay directly from your card
                  </p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="home_text fw-bold col-lg-10 mx-auto">3. Receivers details</p>
                  <p className="home_text mt-3 col-lg-10 mx-auto">
                    Enter receiver’s details (Username, Bank account details, or pay directly through payment sent by
                    receiver), and Send.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row section_margin">
          <div className="col-md-6 mb-4">
            <div className="card_section_left p-4 p-lg-5">
              <h4 className="text-center home_sub_title col-lg-8 mx-auto">Lorem Ipsum is simply dummy text.</h4>
              <p className="text-center home_text my-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <ButtonComponent
                  props={{
                    anchor: "View More",
                    style: "account_btn px-5 f-14",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card_section_right position-relative p-4 p-lg-5 text-white">
              <span className="bell xs_hide">
                <img src={bell} alt="" />
              </span>
              <h4 className="text-center home_sub_title text-white col-lg-8 mx-auto">
                Lorem Ipsum is simply dummy text.
              </h4>
              <p className="text-center home_text text-white my-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <ButtonComponent
                  props={{
                    anchor: "View More",
                    style: "secondary_btn px-5 f-14",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Testimonial />
    </WebsiteLayout>
  );
};

export default Homepage;
