import { NavLink, useLocation } from "react-router-dom";
import { logo } from "../../../assets/images/imageComponents";

const OnboardingLayout = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <div className="onboarding_bg vh-100 vw-100 d-flex align-items-center justify-content-center">
        <div className="pt-5">
          <div className="d-flex align-items-center justify-content-center">
            <img src={logo} alt="" />
            <h1 className="logo_text mb-0 ms-2">Swiftwaze</h1>
          </div>
          <div className="mt-4">
            <div className="bg-white onboarding_width rounded px-4 pb-2 mx-auto">
              {location.pathname === "/sign-in" && (
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    to="/sign-in"
                    style={({ isActive }) => ({
                      color: isActive && "#333333",
                      borderTop: isActive
                        ? "3px solid #333333"
                        : "3px solid transparent",
                    })}
                    className="home_text px-3 text_muted mt-0 text-decoration-none pt-2"
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    to="/sign-up"
                    style={({ isActive }) => ({
                      color: isActive && "#333333",
                      borderTop: isActive
                        ? "3px solid #333333"
                        : "3px solid transparent",
                    })}
                    className="px-3 home_text text_muted mt-0 text-decoration-none pt-2"
                  >
                    Sign Up
                  </NavLink>
                </div>
              )}
              {location.pathname === "/sign-up" && (
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    to="/sign-in"
                    style={({ isActive }) => ({
                      color: isActive && "#333333",
                      borderTop: isActive
                        ? "3px solid #333333"
                        : "3px solid transparent",
                    })}
                    className="home_text px-3 text_muted mt-0 text-decoration-none pt-2"
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    to="/sign-up"
                    style={({ isActive }) => ({
                      color: isActive && "#333333",
                      borderTop: isActive
                        ? "3px solid #333333"
                        : "3px solid transparent",
                    })}
                    className="px-3 home_text text_muted mt-0 text-decoration-none pt-2"
                  >
                    Sign Up
                  </NavLink>
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingLayout;
