import { avatar } from "../../../../assets/images/imageComponents";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import Profile from "../profile";

const Security = () => {
  return (
    <>
      <Profile>
        <div className="col-lg-10 col-xl-8 p-lg-5 general_section">
          <div className="row">
            <div className="col-md-4 d-flex align-items-center">
              <h6>New Transaction Pin</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Confirm Pin</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Old Password</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>New Password</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Confirm Password</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center"></div>
            <div className="col-md-8">
              <ButtonComponent
                props={{
                  anchor: "Update Password",
                  style: "onboarding_btn w-100 text-uppercase f-14",
                }}
              />
            </div>
          </div>
        </div>
      </Profile>
    </>
  );
};

export default Security;
