import DashboardLayout from "../../../shared/layouts/dashboardLayout";
import UserComponent from "../../../shared/components/userProfile/userComponent";
import FloatInputField from "../../../shared/components/floatInputField";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardUserProfile from "../../../shared/components/userProfile/dashboard/dashboardUserProfile";

const Profile = ({ children }) => {
  const navigate = useNavigate("");
  const location = useLocation("");

  return (
    <DashboardLayout>
      <div className="scroll_none container-fluid">
        <div className="row">
          <div className="col-lg-9 scroll_y vh-100">
            <div className="container-fluid">
              <div className="py-4">
                <div className="d-flex block justify-content-between">
                  <h3 className="dashboard_header">My Profile</h3>
                  <FloatInputField
                    props={{
                      label: "Search",
                      type: "search",
                      style: "w-100 input_style rounded border",
                    }}
                  />
                </div>
                <div className="mt-5 scroll_x d-flex align-items-center">
                  <button
                    className={`bg-transparent profile_tab text-muted border-0 ${
                      location.pathname === "/profile/general" && "active_tab"
                    }`}
                    onClick={() => {
                      navigate("/profile/general");
                    }}
                  >
                    General Profile
                  </button>
                  <button
                    onClick={() => navigate("/profile/documents")}
                    className={`bg-transparent profile_tab text-muted border-0 ${
                      location.pathname === "/profile/documents" && "active_tab"
                    }`}
                  >
                    Upload Documents
                  </button>
                  <button
                    onClick={() => navigate("/profile/security")}
                    className={`bg-transparent profile_tab text-muted border-0 ${
                      location.pathname === "/profile/security" && "active_tab"
                    }`}
                  >
                    Security
                  </button>
                  <button
                    onClick={() => navigate("/profile/help")}
                    className={`bg-transparent profile_tab text-muted border-0 ${
                      location.pathname === "/profile/help" && "active_tab"
                    }`}
                  >
                    Help & Feedback
                  </button>
                  <button
                    onClick={() => navigate("/profile/privacy")}
                    className={`bg-transparent profile_tab text-muted border-0 ${
                      location.pathname === "/profile/privacy" && "active_tab"
                    }`}
                  >
                    Privacy Policy
                  </button>
                </div>
                <div className="bg-white mt-4 p-4 rounded">{children}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 xs_hide sm_hide md_hide ps-0 scroll_y vh-100">
            <UserComponent>
              <DashboardUserProfile />
            </UserComponent>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;
