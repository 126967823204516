import { useMutation } from "react-query";
import { axiosConfig, url } from "../../../shared/config";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import axios from "axios";
import OnboardingLayout from "../../../shared/layouts/onboardingLayout";
import OtpInput from "react-otp-input";
import { useState } from "react";

const PasswordReset = () => {
  const [otp, setOtp] = useState("");

  const handleOTP = (otp) => setOtp(otp);

  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation((formData) => {
    return axios.post(`${url}/login`, formData, axiosConfig);
  });

  const navigate = useNavigate();

  return (
    <OnboardingLayout>
      <div className="onboarding_scroll_y d-flex align-items-center">
        <div className="">
          <h3 className="fw-bold mb-4">Reset Password</h3>
          <form className="row">
            <div className="col-12">
              <label htmlFor="email">
                Enter the one-time-password sent to your email
              </label>
              <div className="mb-4 mt-3 col-12 d-flex justify-content-center">
                <OtpInput
                  value={otp}
                  onChange={handleOTP}
                  numInputs={4}
                  inputStyle="otp_style"
                />
              </div>
            </div>
          </form>
          <div className="col-12">
            <button
              className="primary_btn mt-4"
              onClick={() => navigate("/change-password")}
            >
              {/* {mutation.isLoading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-3"
                    />
                  )} */}
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default PasswordReset;
