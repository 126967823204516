import { useForm } from "react-hook-form";
import IconsComponent from "../../assets/icons/iconsComponent";
import { support_avatar, Worldmap } from "../../assets/images/imageComponents";
import ButtonComponent from "../../shared/components/buttonComponent";
import WebsiteLayout from "../../shared/layouts/websiteLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import axios from "axios";
import { axiosConfig, url } from "../../shared/config";
import { api } from "../../shared/helpers/config";
import { useState } from "react";

const Support = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  const mutation = useMutation((formData) => {
    return axios.post(`${url}/${api.contact}`, formData, axiosConfig);
  });
  const schema = yup.object({
    fname: yup.string().required("First name is required"),
    lname: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required").email("Invalid email format").max(300),
    msg: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("msg", message);

    try {
      const data = await mutation.mutateAsync(formData);
      await enqueueSnackbar(data.data.msg, {
        variant: data.data.status ? "success" : "error",
      });
      data.data.status && window.location.reload();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <WebsiteLayout>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-6 d-flex align-items-center">
            <div className="">
              <img className="xs_hide sm_hide" src={support_avatar} alt="" />
              <h3 className="support_header my-4">Get in Touch</h3>
              <p className="support_text col-lg-9">
                Let us build something beautiful together. For partnerships, funding or questions about our services
                please feel free to reach out to us through the channels below or fill the form.
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <img className="w-100" src={Worldmap} alt="" />
          </div>
        </div>
        <div className="py-5 sm_hide xs_hide md_hide">
          <div className="d-flex align-items-center justify-content-between">
            <div className="col-md-3 border-end">
              <h6 className="social_header">Follow us</h6>
              <div className="d-flex align-items-center">
                <a href="https://facebook.com/Swiftwaze" target="_blank" rel="noopener noreferrer">
                  <IconsComponent props="facebook" />
                </a>
                <a href="https://twitter.com/swiftwaze" target="_blank" rel="noopener noreferrer">
                  <IconsComponent props="twitter" />
                </a>
                <a href="https://www.linkedin.com/company/swiftwaze/" target="_blank" rel="noopener noreferrer">
                  <IconsComponent props="linkedin" />
                </a>
                <a href="https://www.instagram.com/swiftwaze/" target="_blank" rel="noopener noreferrer">
                  <IconsComponent props="instagram" />
                </a>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="d-flex text-center justify-content-center">
                <IconsComponent props="telephone" />
                <a href="tel:+94 4444 5555 6" className="support_text text-decoration-none ms-4">
                  +1 (929) 417 - 9726
                </a>
              </div>
            </div>
            <div className="col-md-3 border-start ps-lg-5">
              <div className="d-flex justify-content-center">
                <IconsComponent props="location" />
                <p className="support_text ms-4">
                  support@swiftwaze.com <br />
                  partnerships@swiftwaze.com
                </p>
              </div>
            </div>
            <div className="col-md-3 border-start ps-lg-5">
              <div className="d-flex justify-content-center">
                <IconsComponent props="location" />
                <p className="support_text ms-4">
                  Lagos, Nigeria. <br />
                  San Francisco,USA.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stats_section py-5">
        <div className="container col-lg-8 mx-auto">
          <h3 className="contact_header text-center">Get in touch</h3>
          <p className="contact_text text-center">We are excited to hear from you and will reply you within 24 hours</p>
          <form className="row" action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-6 mb-4">
              <label htmlFor="" className="text-white w-100 mb-2">
                First Name
              </label>
              <input type="text" {...register("fname")} className="contact_input w-100" />
              <p className="mt-2 text-danger f-12">{errors.fname?.message}</p>
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="" className="text-white w-100 mb-2">
                Last Name
              </label>
              <input type="text" {...register("lname")} className="contact_input w-100" />
              <p className="mt-2 text-danger f-12">{errors.lname?.message}</p>
            </div>
            <div className="col-md-12 mb-4">
              <label htmlFor="" className="text-white w-100 mb-2">
                Email Address
              </label>
              <input type="text" {...register("email")} className="contact_input w-100" />
              <p className="mt-2 text-danger f-12">{errors.email?.message}</p>
            </div>
            <div className="col-md-12 mb-4">
              <label htmlFor="" className="text-white w-100 mb-2">
                Message
              </label>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                className="contact_textarea w-100"
                cols="30"
                rows="10"
              ></textarea>
              <p className="mt-2 text-danger f-12">{errors.msg?.message}</p>
            </div>
            <div className="col-md-12 mb-5 d-flex justify-content-end">
              {mutation.isLoading ? (
                <button type="submit" className="contact_input px-4">
                  <span class="spinner-grow spinner-grow" role="status" aria-hidden="true"></span>
                </button>
              ) : (
                <ButtonComponent props={{ anchor: "Get in touch", style: "contact_input px-5" }} />
              )}
            </div>
          </form>
        </div>
      </div>
    </WebsiteLayout>
  );
};

export default Support;
