import IconsComponent from "../../../assets/icons/iconsComponent";

const ContentCarousel = ({ props }) => {
  const { header, body } = props;
  return (
    <div className="testimonial_card p-4">
      <IconsComponent props="inverted comma" />
      <h5 className="mt-4">{header}</h5>
      <p className="mb-4">{body}</p>
    </div>
  );
};

export default ContentCarousel;
