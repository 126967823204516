import {
  about_banner,
  about_banner2,
  about_banner3,
  about_banner4,
  about_vector,
  about_vector2,
  bell,
  our_mission,
  vector,
  vector_3,
} from "../../assets/images/imageComponents";
import StatsComponent from "../../shared/components/statsComponent";
import WebsiteLayout from "../../shared/layouts/websiteLayout";

const About = () => {
  return (
    <WebsiteLayout>
      <div className="col-12 position-relative">
        <img className="right_vector" src={about_vector} alt="" />
      </div>
      <div className="container">
        <div className="row section_margin">
          <div className="col-md-6 mb-3 d-flex align-items-center">
            <div className="row">
              <div className="col-md-6 mb-4 d-flex align-items-end">
                <img className="w-100" src={about_banner} alt="" />
              </div>
              <div className="col-md-6 xs_hide d-flex align-items-end">
                <img className="w-100" src={about_banner2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="">
              <h6 className="about_subheader">Swiftwaze</h6>
              <h3 className="testimonial_header my-4">More possibilities across borders</h3>
              <p className="home_text">
                Swiftwaze is a financial technology company creating global possibilities across borders for individuals
                and businesses to receive money, make transfers, recharge internationally and operate a USD account with
                ease and swiftness.
              </p>
            </div>
          </div>
          <div className="col-md-6 my-4">
            <img className="w-100 h-100" src={about_banner3} alt="" />
          </div>
          <div className="col-md-6 my-4">
            <img className="w-100 h-100" src={about_banner4} alt="" />
          </div>
        </div>
      </div>
      <div className="col-12 position-relative">
        <img className="left_vector" src={about_vector2} alt="" />
      </div>
      <div className="container">
        <div className="row section_margin">
          <h3 className="col-lg-8 banking_section_header" style={{ color: "#525298" }}>
            About Us
          </h3>
          <p className="home_text mt-4 col-lg-10">
            Sending money to friends and family overseas, paying bills, or just keeping track of expenses can be a
            struggle. Fortunately, there is a way to manage everything in one place: Swiftwaze, Swift ways to move and
            manage your money within Africa and across borders with a smile. <br /> <br /> We've all been there: you're
            in a rush, and you need to send some money oversea to a friend or family member who needs it right away. You
            know how much it'll cost, but you don't want to lose your hard-earned cash on a service that won't get the
            job done in time, and you also don’t want them (the recipient) to wait forever to cash the money plus the
            high cost and unreal exchange rates. That's why we created Swiftwaze—to make sending money overseas simple
            and affordable. <br /> <br /> That’s why we are here, starting with Naira and US Dollar wallets. Swiftwaze
            will operate on a global scale: starting with USA to Nigerian Market. We are working on expanding our
            services to the United Kingdom, Canada and Europe. <br /> <br /> We are headquartered in the United States
            as a registered Delaware financial services provider with offices in USA and Lagos, Nigeria where we are
            registered as a Fintech company under the laws of the federal republic of Nigeria. <br /> <br /> We are
            coming soon to Ghana, Rwanda, Morocco, Zambia, Kenya and South Africa.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row section_margin">
          <div className="col-md-6">
            <div className="">
              <h4 className="testimonial_header mt-4">Our Mission</h4>
              <p className="home_text my-4">
                Faster payments. Lower fees. World class service with innovation. <br /> <br /> That's what we're
                building at Swiftwaze. It's our mission to build a financial future where anyone, anywhere can connect
                to the world of international payments. <br /> <br /> We're building an international payment platform
                that enables our users to send and receive money from all over the world, to pay for goods and services
                from almost any location – across borders and in more ways than ever before. <br /> <br /> We are here
                to connect you to the world and all its possibilities, no matter who you are and where you are!
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <img src={our_mission} className="our_mission_img" alt="Our Mission" height={400} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row section_margin">
          <h3 className="core_header my-4 ps-md-4">Our Core Values</h3>
          <div className="col-md-6 mb-3">
            <div className="core_content_bg shadow">
              <h5 className="core_subheader">Be Swift and Simple</h5>
              <p className="core_body">
                We understand that time is precious and we believe that the world should be a simple place. This is key
                in our services.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="core_content_bg shadow">
              <h5 className="core_subheader">Be Innovative and Affordable</h5>
              <p className="core_body">
                Delivering superior customer interactions and products for our customers while being affordable is key
                to our growth and business.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="core_content_bg shadow">
              <h5 className="core_subheader">Be Transparent</h5>
              <p className="core_body">
                Without transparency, trust cannot be built and this is a very important part of our company.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="core_content_bg shadow">
              <h5 className="core_subheader">Be Empathetic</h5>
              <p className="core_body">
                In simple terms, we are here to serve our customers. Understanding their very needs is key.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="xs_hide sm_hide md_hide position-relative">
        <img src={vector} className="vector_1" alt=""/>
        <img src={vector_3} className="vector_3" alt="" />
        <img className="bell" src={bell} alt="" />
        <StatsComponent />
      </div>
    </WebsiteLayout>
  );
};

export default About;
