import { useState } from "react";
import { Drawer } from "antd";
import { avatar, card } from "../../../assets/images/imageComponents";
import DashboardLayout from "../../../shared/layouts/dashboardLayout";
import TableComponent from "../../../shared/components/tableComponent";
import IconsComponent from "../../../assets/icons/iconsComponent";
import ChartComponent from "../../../shared/components/chartComponent";
import TransferMoney from "./transferMoney/transferMoney";
import PayBills from "./payBill/paybill";
import Airtime from "./airtime/airtime";
import TopUp from "./topUp/topUp";
import RequestPayout from "./requestPayout/requestPayout";
import UserComponent from "../../../shared/components/userProfile/userComponent";
import DashboardUserProfile from "../../../shared/components/userProfile/dashboard/dashboardUserProfile";

const Dashboard = () => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState();

  const onClose = () => {
    setVisible(!visible);
  };

  const data = [
    {
      id: "1000",
      status: "success",
      transaction_type: "transfer",
      reference: "yru790e",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1001",
      status: "warning",
      reference: "yru790e",
      transaction_type: "Withdrawal",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1002",
      status: "danger",
      reference: "yru790e",
      transaction_type: "transfer",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
  ];

  return (
    <DashboardLayout>
      <div className="scroll_none container-fluid">
        <div className="row">
          <div className="col-lg-9 scroll_y vh-100">
            <div className="container-fluid">
              <div className="py-4">
                <h6 className="dashboard_date">
                  Sat, 9th April, 2022 | Partly Cloudy
                </h6>
                <h3 className="dashboard_header">Hello, Christopher A.</h3>

                <div className="d-flex align-items-center scroll_x justify-content-between shadow mt-4 p-4 bg-white rounded">
                  <button
                    onClick={() => {
                      setShow("send money");
                      setVisible(true);
                    }}
                    className="d-flex flex-column border-0 bg-transparent justify-content-center"
                  >
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="send-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">Send Money</p>
                  </button>
                  <button
                    onClick={() => {
                      setShow("pay bills");
                      setVisible(true);
                    }}
                    className="d-flex mx-4 flex-column border-0 bg-transparent justify-content-center"
                  >
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="paybills-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">Pay Bills</p>
                  </button>
                  <button
                    onClick={() => {
                      setShow("top up");
                      setVisible(true);
                    }}
                    className="d-flex flex-column border-0 bg-transparent justify-content-center"
                  >
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="topup-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">Top Up</p>
                  </button>
                  <button
                    onClick={() => {
                      setShow("airtime");
                      setVisible(true);
                    }}
                    className="d-flex mx-4 flex-column border-0 bg-transparent justify-content-center"
                  >
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="airtime-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">Airtime</p>
                  </button>
                  <button
                    onClick={() => {
                      setShow("request payout");
                      setVisible(true);
                    }}
                    className="d-flex flex-column border-0 bg-transparent justify-content-center"
                  >
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="request-payment-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">
                      Request Payment
                    </p>
                  </button>
                  {/* <button className="d-flex flex-column border-0 bg-transparent justify-content-center">
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="more-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">More</p>
                  </button> */}
                </div>
                <div className="mt-5 d-flex justify-content-between mb-4">
                  <div className="d-flex align-items-center block">
                    <button className="bg-transparent border-0 px- me-3">
                      Wallet
                    </button>
                    <button className="bg-transparent border-0 px-">
                      Card Information
                    </button>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="bg-white border px-4 py-2 dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Duration
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button className="dropdown-item" type="button">
                          Daily
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Monthly
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="my-5 mt-4">
                  <ChartComponent />
                </div>
                <div className="table-responsive">
                  <TableComponent props={data} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 xs_hide sm_hide md_hide ps-0 scroll_y vh-100">
            <UserComponent>
              <DashboardUserProfile />
            </UserComponent>
          </div>
        </div>
      </div>

      {/* Drawer */}
      <Drawer
        closable={false}
        placement="right"
        width={430}
        onClose={onClose}
        visible={visible}
      >
        {show === "send money" && <TransferMoney />}
        {show === "pay bills" && <PayBills />}
        {show === "airtime" && <Airtime />}
        {show === "top up" && <TopUp />}
        {show === "request payout" && <RequestPayout />}
      </Drawer>
    </DashboardLayout>
  );
};

export default Dashboard;
