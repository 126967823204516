import { useState } from "react";
import { Select, Radio } from "antd";
import OtpInput from "react-otp-input";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import TransferSuccess from "../transferMoney/transferSuccess";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { Option } = Select;

const Airtime = () => {
  const [otp, setOtp] = useState("");
  const [value, setValue] = useState("new beneficiary");
  const [airtimeScreen, setAirtimeScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const handleOTP = (otp) => setOtp(otp);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleOtpScreen = (e) => {
    e.preventDefault();
    setAirtimeScreen(!airtimeScreen);
    setOtpScreen(!otpScreen);
  };

  const handleSuccessScreen = (e) => {
    e.preventDefault();
    setOtpScreen(!otpScreen);
    setSuccessScreen(!successScreen);
  };

  return (
    <div className="container">
      {airtimeScreen && (
        <>
          <h3 className="drawer_header">Airtime</h3>
          <p className="drawer_subheader">
            To make a transfer provide the detials below
          </p>
          <div className="mt-5">
            <Select defaultValue="lucy" onChange={handleChange}>
              <Option value="jack">NGN Wallet Balance</Option>
              <Option value="lucy">Dollar Wallet Balance</Option>
            </Select>
            <h3 className="mt-2 balance_header">₦ 3,041,730</h3>
            <form action="" className="row">
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Select Network",
                    style: "w-100",
                    type: "dropdown",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Amount",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <div className="col-12 my-4 d-flex justify-content-end">
                <Radio.Group
                  className="d-flex"
                  onChange={onChange}
                  value={value}
                >
                  <Radio value="new beneficiary">New Beneficiary</Radio>
                  <Radio value="save beneficiary">Save Beneficiary</Radio>
                </Radio.Group>
              </div>
              {value === "new beneficiary" ? (
                <div className="col-12">
                  <FloatInputField
                    props={{
                      label: "Phone Number",
                      type: "number",
                      style: "w-100 input_style rounded border",
                    }}
                  />
                </div>
              ) : (
                <div className="col-12">
                  <FloatInputField
                    props={{
                      label: "Select Beneficiary",
                      style: "w-100",
                      type: "dropdown",
                    }}
                  />
                </div>
              )}
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "You'd Pay",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <ButtonComponent
                  props={{
                    anchor: "proceed",
                    style: "onboarding_btn w-100 text-uppercase",
                    handleClick: handleOtpScreen,
                  }}
                />
              </div>
            </form>
          </div>
        </>
      )}
      {otpScreen && (
        <>
          <h3 className="drawer_header">Transaction Pin</h3>
          <p className="drawer_subheader">
            Provide transaction to fund wallet from card
          </p>
          <div className="mt-5">
            <form action="" className="row">
              <div className="col-12 mt-5">
                <p className="otp_text">Enter your 4 digit pin</p>
                <div className="mb-5 col-12 d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleOTP}
                    numInputs={4}
                    inputStyle="otp_style"
                  />
                </div>
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "Transfer",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleSuccessScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {successScreen && (
        <>
          <TransferSuccess />
        </>
      )}
    </div>
  );
};

export default Airtime;
