import { Checkbox } from "antd";

const TransferSection = () => {
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };

  return (
    <>
      <h6 className="transfer_detail_subheader mt-3">
        Transfer to the following account
      </h6>
      <h3 className="transfer_detail_header">0078746372</h3>
      <h5 className="transfer_detail_subtitle">
        Swiftwaze(Christopher Aniedi) Wema Bank
      </h5>
      <div className="col-12 mt-4">
        <Checkbox onChange={onChange}>I have made the transfer</Checkbox>
      </div>
    </>
  );
};

export default TransferSection;
