import { carousel_image1 } from "../../../assets/images/imageComponents";

const ImageCarousel = () => {
  return (
    <>
      <div className="">
        <img src={carousel_image1} alt="" />
        <h6 className="image_carousel_header mt-3">CEO</h6>
        <h6 className="image_carousel_subheader">John kabros</h6>
      </div>
    </>
  );
};

export default ImageCarousel;
