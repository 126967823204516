import { NavLink } from "react-router-dom";
import { logo } from "../../../assets/images/imageComponents";
import ButtonComponent from "../../components/buttonComponent";

const Navbar = ({ toggle }) => {
  return (
    <>
      <div className="border-bottom">
        <div className="container">
          <div className="topbar d-flex align-items-center justify-content-between">
            <NavLink className="text-decoration-none" to="/">
              <div className="d-flex align-items-center">
                <img src={logo} alt="" />
                <h1 className="logo_text mb-0 ms-2">Swiftwaze</h1>
              </div>
            </NavLink>
            <div className="d-flex align-items-center justify-content-between xs_hide sm_hide md_hide">
              <NavLink to="/" className="nav_link">
                Home
              </NavLink>
              <NavLink to="/about" className="nav_link  me-4 ms-4">
                About Swiftwaze
              </NavLink>
              <NavLink to="/contact" className="nav_link  md_hide">
                Help Center
              </NavLink>
              {/* <NavLink to="/blog" className="nav_link  ms-4 me-4">
                Blog
              </NavLink> */}
            </div>
            {/* <NavLink className="text-decoration-none xs_hide sm_hide md_hide" to="/sign-in">
              <div className="d-flex align-items-center">
                <ButtonComponent
                  props={{
                    anchor: "Account",
                    style: "account_btn px-4",
                  }}
                />
              </div>
            </NavLink> */}
            <button className="btn lg_hide xl_hide" onClick={toggle}>
              <i className="pi pi-align-left" style={{ fontSize: "25px" }}></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
