import { useState } from "react";
import { Drawer } from "antd";
import { NavLink } from "react-router-dom";
import Footer from "./footer";
import Navbar from "./navbar";
import ButtonComponent from "../../components/buttonComponent";

const WebsiteLayout = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      {children}
      <Footer />
      <div className="site-drawer-render-in-current-wrapper">
        <Drawer
          // title="Basic Drawer"
          placement="right"
          closable={true}
          onClose={toggle}
          visible={visible}
          getContainer={false}
          width="250px"
        >
          <ul className=" list-unstyled">
            <li className="mb-3">
              <NavLink to="/" className="nav_link">
                Home
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/about" className="nav_link">
                About Us
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/contact" className="nav_link">
                Help Center
              </NavLink>
            </li>
            {/* <li className="mb-3">
              <NavLink to="/blog" className="nav_link ">
                Blog
              </NavLink>
            </li> */}
          </ul>
          {/* <NavLink to="/sign-in">
            <ButtonComponent
              props={{
                anchor: "Account",
                style: "account_btn px-4 w-100",
              }}
            />
          </NavLink> */}
        </Drawer>
      </div>
    </>
  );
};

export default WebsiteLayout;
