import { useState } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { Radio, Empty, Select } from "antd";
import { avatar } from "../../../../assets/images/imageComponents";
import OtpInput from "react-otp-input";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import CardSection from "./cardSection";
import SavedCard from "./savedCard";
import TransferSuccess from "./transferSuccess";
import OTPScreen from "../../../../shared/components/otpScreen";

const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const TransferMoney = () => {
  const [otp, setOtp] = useState("");
  const [value, setValue] = useState("new beneficiary");
  const [newCard, setNewCard] = useState(false);
  const [savedCard, setSavedCard] = useState(false);
  const [beneficiaryScreen, setBeneficiaryScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);
  const [cardScreen, setCardScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleOTP = (otp) => setOtp(otp);

  const handleCardScreen = (e) => {
    e.preventDefault();
    setBeneficiaryScreen(!beneficiaryScreen);
    setCardScreen(!cardScreen);
  };

  const handleOtpScreen = (e) => {
    e.preventDefault();
    setCardScreen(!cardScreen);
    setOtpScreen(!otpScreen);
  };

  const handleSuccessScreen = (e) => {
    e.preventDefault();
    setOtpScreen(!otpScreen);
    setSuccessScreen(!successScreen);
  };
  return (
    <div className="container">
      {beneficiaryScreen && (
        <>
          <h3 className="drawer_header">Transfer Money</h3>
          <p className="drawer_subheader">
            To make a transfer provide the detials below
          </p>
          <div className="mt-5">
            <Select defaultValue="lucy" onChange={handleChange}>
              <Option value="jack">NGN Wallet Balance</Option>
              <Option value="lucy">Dollar Wallet Balance</Option>
            </Select>
            <h3 className="mt-2 balance_header">₦ 3,041,730</h3>
            <form action="" className="row">
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Enter Amount",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <Radio.Group
                  className="d-flex"
                  onChange={onChange}
                  value={value}
                >
                  <Radio value="new beneficiary">New Beneficiary</Radio>
                  <Radio value="contact">Select from Contact</Radio>
                </Radio.Group>
              </div>
              <div className="col-12 mt-4">
                {value === "new beneficiary" && (
                  <div className="">
                    <div className="account_info_section p-3">
                      <div className="row mt-2">
                        <div className="col-6 mb-3">
                          <FloatInputField
                            props={{
                              label: "Account Number",
                              type: "number",
                              style: "w-100 input_style rounded",
                            }}
                          />
                        </div>
                        <div className="col-6 mb-3">
                          <FloatInputField
                            props={{
                              label: "Account Number",
                              type: "dropdown",
                              style: "w-100 input_style rounded",
                            }}
                          />
                        </div>
                        <div className="col-12 border-top pt-4">
                          <FloatInputField
                            props={{
                              label: "Beneficiary Name",
                              type: "text",
                              style: "w-100 input_style rounded ",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <FloatInputField
                        props={{
                          label: "Description",
                          type: "textarea",
                          style: "w-100 input_textarea",
                        }}
                      />
                    </div>
                  </div>
                )}
                {value === "contact" && (
                  <div className="">
                    <div className="account_info_section p-3">
                      <div className="row mt-2">
                        <div className="col-12">
                          <label className="beneficiary_label" htmlFor="">
                            Select Beneficiary
                          </label>
                          <div className="btn-group w-100">
                            <button
                              type="button"
                              className="bg-white beneficiary_name border-0 dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Christopher Aniedi Udoh
                            </button>
                            <ul className="dropdown-menu dropdown-menu-start">
                              <li>
                                <button className="dropdown-item" type="button">
                                  John Doe
                                </button>
                              </li>
                              <li>
                                <button className="dropdown-item" type="button">
                                  James Milner
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="beneficiary_preview p-3 d-flex align-items-center">
                        <img src={avatar} alt="" />
                        <div className="ms-3">
                          <h6 className="mb-0">Chris Aniedi</h6>
                          <p className="mb-0">First Bank - 0987 3422 8756</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <FloatInputField
                        props={{
                          label: "Description",
                          type: "textarea",
                          style: "w-100 input_textarea",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "proceed",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleCardScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {otpScreen && (
        <>
          <h3 className="drawer_header">Transaction Pin</h3>
          <p className="drawer_subheader">
            Provide transaction to fund wallet from card
          </p>
          <div className="mt-5">
            <form action="" className="row">
              <div className="col-12 mt-5">
                <p className="otp_text">Enter your 4 digit pin</p>
                <div className="mb-5 col-12 d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleOTP}
                    numInputs={4}
                    inputStyle="otp_style"
                  />
                </div>
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "Transfer",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleSuccessScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {cardScreen && (
        <>
          <h3 className="drawer_header">Transfer Money</h3>
          <p className="drawer_subheader">
            To make a transfer provide the detials below
          </p>
          <div className="mt-5">
            <form action="" className="row">
              <PanelMenu
                onClick={() => {
                  setNewCard(!newCard);
                }}
                model={[
                  {
                    label: (
                      <div className="ms-3">
                        <h5>New Card</h5>
                        <p className="mb-0 text-muted">
                          Use a new credit/debit card
                        </p>
                      </div>
                    ),
                    items: [
                      {
                        label: (
                          <div className="d-flex align-items-center ps-5 mx-auto text-center justify-content-center">
                            <Empty
                              style={{ width: "100px" }}
                              className="text-center ms-5 ps-4 mx-auto"
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          </div>
                        ),
                      },
                    ],
                  },
                ]}
              />
              {newCard && (
                <div className="col-12 mt-4">
                  <CardSection />
                </div>
              )}
              <div className="col-12 mt-4">
                <PanelMenu
                  onClick={() => {
                    setSavedCard(!savedCard);
                  }}
                  model={[
                    {
                      label: (
                        <div className="ms-3">
                          <h5>Saved Card</h5>
                          <p className="mb-0 text-muted">
                            Use your saved credit/debit card
                          </p>
                        </div>
                      ),
                      items: [
                        {
                          label: (
                            <div className="d-flex align-items-center ps-5 mx-auto text-center justify-content-center">
                              <Empty
                                style={{ width: "100px" }}
                                className="text-center ms-5 ps-4 mx-auto"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                />
              </div>
              {savedCard && (
                <div className="col-12 mt-4">
                  <SavedCard />
                </div>
              )}
              <div className="col-12 mt-4">
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "proceed",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleOtpScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div></div>
        </>
      )}
      {successScreen && (
        <>
          <TransferSuccess />
        </>
      )}
    </div>
  );
};

export default TransferMoney;
