import IconsComponent from "../../assets/icons/iconsComponent";
import { bell, vector, vector_2, vector_3 } from "../../assets/images/imageComponents";
import StatsComponent from "../../shared/components/statsComponent";
import Testimonial from "../../shared/components/testimonial";
import WebsiteLayout from "../../shared/layouts/websiteLayout";

const WhySwiftwaze = () => {
  return (
    <WebsiteLayout>
      <div className="container position-relative">
        <div className="col-md-8 col-lg-7 mx-auto mt-5 pt-5">
          <h3 className="text-center">Why do people choose Swiftwaze</h3>
          <p className="home_text text-center my-4 col-lg-9 mx-auto">
            Swift and Free local transfers, and low cross border rates
          </p>
        </div>
        <div className="row section_margin">
          <div className="col-12">
            <div className="security_section p-4 p-lg-5">
              <div className="row">
                <div className="col-md-6 pb-3 border-bottom remove_border">
                  <div className="d-flex">
                    <div
                      className="security_icon_bg p-3 d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#F1E8FF" }}
                    >
                      <IconsComponent props="security icon" />
                    </div>
                    <div className="ms-3">
                      <h5 className="security_header fw_bold">Secured Funds</h5>
                      <p className="home_text">Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 border-bottom border-start remove_border">
                  <div className="d-flex ms-lg-4">
                    <div
                      className="security_icon_bg p-3 d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#FFF2F2" }}
                    >
                      <IconsComponent props="security icon" />
                    </div>
                    <div className="ms-3">
                      <h5 className="security_header fw_bold">Fruad Protection</h5>
                      <p className="home_text">Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-4">
                  <div className="d-flex">
                    <div
                      className="security_icon_bg p-3 d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#E2F3FF" }}
                    >
                      <IconsComponent props="security icon" />
                    </div>
                    <div className="ms-3">
                      <h5 className="security_header fw_bold">Data Security</h5>
                      <p className="home_text">Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 remove_border border-start pt-4">
                  <div className="d-flex ms-lg-4">
                    <div
                      className="security_icon_bg d-flex align-items-center justify-content-center p-3"
                      style={{ backgroundColor: "#FFE7FB" }}
                    >
                      <IconsComponent props="security icon" />
                    </div>
                    <div className="ms-3">
                      <h5 className="security_header fw_bold">Secure Transactions</h5>
                      <p className="home_text">Advanced autonomous technologies to make life simple</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row section_margin mb-0">
            <h3 className="col-lg-8 banking_section_header">How Swiftwaze is design to meet your banking needs</h3>
            <div className="row my-4">
              <div className="col-md-4 mb-4">
                <div className="banking_icon_bg"></div>
                <h3 className="banking_header mt-4">Financial data</h3>
                <p className="home_text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book.
                </p>
              </div>
              <div className="col-md-4 mb-4">
                <div className="banking_icon_bg"></div>
                <h3 className="banking_header mt-4">Bank Statement</h3>
                <p className="home_text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book.
                </p>
              </div>
              <div className="col-md-4 mb-4">
                <div className="banking_icon_bg"></div>
                <h3 className="banking_header mt-4">Direct Payment</h3>
                <p className="home_text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="xs_hide sm_hide md_hide">
          <img className="bank_bell" src={bell} alt="" />
          <img className="vector_1" src={vector} alt="" />
          <img className="vector_2" src={vector_2} alt="" />
          <img className="vector_3" src={vector_3} alt="" />
        </div>
      </div>
      <div className="xs_hide sm_hide md_hide">
        <StatsComponent />
      </div>
      <Testimonial />
    </WebsiteLayout>
  );
};

export default WhySwiftwaze;
