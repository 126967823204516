import ButtonComponent from "../../buttonComponent";
import FloatInputField from "../../floatInputField";

const TransactionUserProfile = () => {
  return (
    <div className="col-12">
      <div className="my-5 mb-4">
        <div className="text-center">
          {/* <img src={bank_icon} alt="" /> */}
          <h5 className="mt-4" style={{ fontWeight: 600, fontSize: "25px" }}>
            Transaction Details
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <h4>- ₦ 3,041,730</h4>
          <p className="text-success f-12">Success</p>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <h4 className="text-muted f-12">Recipient</h4>
          <div className="text-end ms-5">
            <h4 className="f-12">Christopher Aniedi Udoh</h4>
            <p className="f-9 text-muted mb-0">Guranteed Trust Bank</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <h4 className="text-muted f-12">Type</h4>
          <h4 className="f-12">Airtime Purchase</h4>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <h4 className="text-muted f-12">Reference</h4>
          <h4 className="f-12">Rt7890yt</h4>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <h4 className="text-muted f-12">Paid On</h4>
          <h4 className="f-12">Wed, May 18th, 2022 at 8:15am</h4>
        </div>
        <div className="mt-4">
          <FloatInputField
            props={{
              label: "Description",
              type: "textarea",
              style: "w-100 input_textarea",
            }}
          />
        </div>
        <div className="mt-4">
          <ButtonComponent
            props={{
              anchor: "Download Receipt",
              style: "onboarding_btn w-100 text-uppercase f-12",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionUserProfile;
