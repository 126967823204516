import { avatar, blog_banner } from "../../assets/images/imageComponents";
import BlogPost from "../../shared/components/blogPost";
import ButtonComponent from "../../shared/components/buttonComponent";
import SearchComponent from "../../shared/components/searchComponent";
import Subscription from "../../shared/components/subscription";
import WebsiteLayout from "../../shared/layouts/websiteLayout";

const Blog = () => {
  return (
    <WebsiteLayout>
      <div className="row section_margin mt-5 text-center">
        <div className="col-md-7 col-lg-3 mx-auto">
          <h1 className="support_header">
            Latest News <br />{" "}
            <span className="support_header" style={{ color: "#525298" }}>
              Updates
            </span>{" "}
          </h1>
          <p className="mt-4 home_text">
            Lorem Ipsum is simply dummy text of the printing .
          </p>
          <div className="mt-5 w-100">
            <SearchComponent
              props={{
                anchor: "Search",
                style: "blog_search",
                floatStyle: "float_text",
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-10 col-lg-8 mt-5 mx-auto">
            <div className="d-flex scroll_x align-items-center justify-content-between">
              {[1, 2, 3, 4, 5].map((buttons) => (
                <ButtonComponent
                  props={{ anchor: "Lorem", style: "blog_button px-5 me-4" }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row section_margin">
          <div className="col-md-6 mb-3 d-flex align-items-center">
            <img src={blog_banner} className="w-100 rounded" alt="" />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="">
              <h3 className="col-lg-10">
                Lorem Ipsum is simply dummy text of the printing.
              </h3>
              <p className="my-5 mt-4 home_text col-lg-9">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the .
              </p>
              <div className="d-flex align-items-center">
                <img src={avatar} alt="" />
                <div className="d-flex w-100 ms-3 align-items-center justify-content-between">
                  <div className="me-4">
                    <h6>Name here</h6>
                    <p className="mb-0 home_text">20.12.2020</p>
                  </div>
                  <ButtonComponent
                    props={{ anchor: "Read More", style: "read_more btn" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row section_margin mb-5">
          {[1, 2, 3, 4, 5, 6].map((post, i) => (
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-5">
              <BlogPost id={i} />
            </div>
          ))}
        </div>
      </div>
      <Subscription />
    </WebsiteLayout>
  );
};

export default Blog;
