import { NavLink, useNavigate } from "react-router-dom";
import { logo } from "../../../assets/images/imageComponents";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import IconsComponent from "../../../assets/icons/iconsComponent";
import ButtonComponent from "../../components/buttonComponent";

const LeftSide = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/sign-in");
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="text-center mt-4 mb-4">
          <img src={logo} width="50px" alt="" />
        </div>
        <ul className="list-unstyled d-flex flex-column leftside">
          <li className="text-center p-4">
            <NavLink className="text-decoration-none" to="/dashboard">
              <IconsComponent props="dashboard" />
              <p className="sidebar_links mt-3 mb-0">Dashboard</p>
            </NavLink>
          </li>
          <li className="text-center p-4">
            <NavLink className="text-decoration-none" to="/transactions/wallet">
              <IconsComponent props="transaction-icon" />
              <p className="sidebar_links mt-3 mb-0">Transaction</p>
            </NavLink>
          </li>
          <li className="text-center p-4">
            <NavLink className="text-decoration-none" to="/wallet">
              <IconsComponent props="wallet-icon" />
              <p className="sidebar_links mt-3 mb-0">Wallets</p>
            </NavLink>
          </li>
          <li className="text-center p-4">
            <NavLink className="text-decoration-none" to="/profile/general">
              <IconsComponent props="profile-icon" />
              <p className="sidebar_links mt-3 mb-0">My Profile</p>
            </NavLink>
          </li>
          <li className="text-center p-4 click cursor" onClick={handleShow}>
            <IconsComponent props="logout-icon" />
            <p className="sidebar_links mt-3 mb-0">Logout</p>
          </li>
        </ul>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="container">
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="text-danger text-center text-uppercase">
              Logout
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center f-14">
            Are you sure you want to <br /> logout of your account?
          </Modal.Body>
          <Modal.Footer className="border-0 my-4">
            <ButtonComponent
              props={{
                anchor: "Yes, Logout",
                style: "onboarding_btn w-100 text-uppercase f-12",
                handleClick: handleClose,
              }}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default LeftSide;
