import { success_icon } from "../../../../assets/images/imageComponents";
import ButtonComponent from "../../../../shared/components/buttonComponent";

const TransferSuccess = () => {
  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <div className="col-md-12 text-center mx-auto">
          <img src={success_icon} alt="" />
          <h3 className="mt-4">Transaction Successful</h3>
          <p className="mb-5 my-4">
            You have successfully transferred N2,500,000.00 to Christopher
            Aniedi from your NGN Wallet
          </p>
          <ButtonComponent
            props={{
              anchor: "Return to dashboard",
              style: "onboarding_btn w-100 text-uppercase",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TransferSuccess;
