import IconsComponent from "../../../../assets/icons/iconsComponent";
import { card_sample } from "../../../../assets/images/imageComponents";

const WalletUserProfile = () => {
  return (
    <div className="col-12">
      <div className="d-flex align-items-center justify-content-between mt-5">
        <h4 className="mb-0 fw-bold">Active Card</h4>
        <IconsComponent props="option icon" />
      </div>
      <div className="col-12">
        <img className="w-100 mt-3" src={card_sample} alt="" />
      </div>
      <div className="col-12 mt-5">
        <h4 className="mb-0 fw-bold">Recent Activity</h4>
      </div>
      {[1, 2].map((activity) => (
        <div className="d-flex mt-2 mb-4 justify-content-between">
          <div className="d-flex">
            {/* <IconsComponent props="send-icon" /> */}
            <div className="">
              <h6>Fund Account</h6>
              <h6 className="text-muted">03 May, 2022, 4:30 PM</h6>
            </div>
          </div>
          <h5 className="text-muted ms-3">₦3,041</h5>
        </div>
      ))}
    </div>
  );
};

export default WalletUserProfile;
