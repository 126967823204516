import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";
import { axiosConfig, url } from "../../shared/config";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import axios from "axios";
import OnboardingLayout from "../../shared/layouts/onboardingLayout";
import * as yup from "yup";

const SignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch("https://api.github.com/repos/tannerlinsley/react-query").then(
      (res) => res.json()
    )
  );

  const mutation = useMutation((formData) => {
    return axios.post(`${url}/register`, formData, axiosConfig);
  });

  const schema = yup.object({
    fname: yup.string().required("First Name is required").min(3).max(25),
    lname: yup.string().required("Last Name is required").min(3).max(25),
    phone: yup
      .number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .min(11)
      .required("A phone number is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .max(300)
      .required("Email is required"),
    pword: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const data = await mutation.mutateAsync(formData);
      await enqueueSnackbar(data.data.msg, {
        variant: data.data.status ? "success" : "error",
      });
      data.data.status && navigate("/sign-in");
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <OnboardingLayout>
      <div className="onboarding_scroll_y">
        <h3 className="fw-bold">Welcome Back</h3>
        <p className="home_text text-muted">Sign up for an account</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="w-100 onboarding_field shadow-sm"
              {...register("email")}
            />
            <p className="text-danger f-10 mt-2">{errors.email?.message}</p>
          </div>
          <div className="col-12">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              className="w-100 onboarding_field shadow-sm"
              {...register("fname")}
            />
            <p className="text-danger f-10 mt-2">{errors.fname?.message}</p>
          </div>
          <div className="col-12">
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              className="w-100 onboarding_field shadow-sm"
              {...register("lname")}
            />
            <p className="text-danger f-10 mt-2">{errors.lname?.message}</p>
          </div>
          <div className="col-12">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              className="w-100 onboarding_field shadow-sm"
              {...register("phone")}
            />
            <p className="text-danger f-10 mt-2">{errors.phone?.message}</p>
          </div>
          <div className="col-12">
            <label htmlFor="pword">Password</label>
            <input
              type="password"
              className="w-100 onboarding_field shadow-sm"
              {...register("pword")}
            />
            <p className="text-danger f-10 mt-2">{errors.pword?.message}</p>
          </div>
          <button className="primary_btn mt-4">
            {mutation.isLoading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-3"
              />
            )}
            Sign Up
          </button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default SignUp;
