import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContentCarousel from "./contentCarousel";
import ImageCarousel from "./imageCarousel";

const options = {
  margin: 10,
  responsiveClass: true,
  autoplay: true,
  // dots: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const carousel_content = [
  {
    header: "It's swift -",
    body: "100% of our transfers and payments (Home and Oversea) are delivered instantly. So you don’t even have time to start tracking.",
  },
  {
    header: "It's low-cost",
    body: "Get the best value for your money. We will never charge you without your consent. Say no to excessive and unjustified bank charges. We offer the best rates on the market when converting your money from Dollar to naira and vice versa.",
  },
  {
    header: "Sending oversea – ",
    body: "We deliver straight to your recipient's bank account. We deliver even when your recipient doesn’t have a bank account.",
  },
];

const Carousel = ({ type }) => {
  switch (type) {
    case "content":
      return (
        <div className="container-fluid">
          <OwlCarousel {...options}>
            {carousel_content.map((item) => (
              <ContentCarousel props={item} />
            ))}
          </OwlCarousel>
        </div>
      );
      break;

    case "image":
      return (
        <div className="container-fluid">
          <OwlCarousel {...options}>
            {[1, 2, 3, 4, 5, 6, 7].map((items) => (
              <ImageCarousel />
            ))}
          </OwlCarousel>
        </div>
      );
      break;

    default:
      break;
  }
};

export default Carousel;
