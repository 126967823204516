import ButtonComponent from "../../../../shared/components/buttonComponent";
import Profile from "../profile";

const Document = () => {
  return (
    <>
      <Profile>
        <div className="col-12 p-lg-4 general_section">
          <div className="accordion mb-4" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  <div className="">
                    <h5 className="primary_color f-14">Valid Identity Card</h5>
                    <h6 className="text-muted f-12">
                      NIN, Voters Card, Drivers License
                    </h6>
                  </div>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body py-4">
                  <div className="d-flex align-items-center scan_file">
                    <svg
                      width="62"
                      height="62"
                      viewBox="0 0 62 62"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.4">
                        <path
                          d="M2.58325 37.4575C2.58166 34.6041 3.30768 31.7974 4.69268 29.3027C6.07768 26.808 8.07593 24.7076 10.4986 23.2001C11.138 18.2145 13.5722 13.6327 17.3458 10.3123C21.1193 6.99178 25.9735 5.16016 30.9999 5.16016C36.0264 5.16016 40.8806 6.99178 44.6541 10.3123C48.4276 13.6327 50.8618 18.2145 51.5013 23.2001C54.5063 25.0696 56.8437 27.8408 58.1799 31.118C59.5161 34.3951 59.7827 38.0107 58.9416 41.4484C58.1005 44.8861 56.1948 47.9701 53.4965 50.2602C50.7982 52.5502 47.4453 53.9292 43.9166 54.2001L18.0833 54.2492C9.41359 53.5414 2.58325 46.2977 2.58325 37.4575ZM43.5239 49.0489C45.9673 48.8611 48.2888 47.9059 50.1569 46.3197C52.025 44.7336 53.344 42.5977 53.9256 40.2171C54.5072 37.8366 54.3216 35.3331 53.3954 33.0643C52.4691 30.7954 50.8496 28.8774 48.7681 27.584L46.6833 26.2846L46.3733 23.8511C45.8904 20.1148 44.0635 16.6822 41.234 14.1948C38.4045 11.7075 34.766 10.3356 30.9986 10.3356C27.2312 10.3356 23.5928 11.7075 20.7633 14.1948C17.9337 16.6822 16.1068 20.1148 15.6239 23.8511L15.3139 26.2846L13.2343 27.584C11.1529 28.8772 9.53341 30.7951 8.60706 33.0637C7.68071 35.3323 7.49492 37.8356 8.07621 40.216C8.65751 42.5965 9.97617 44.7324 11.8439 46.3187C13.7115 47.9051 16.0327 48.8606 18.4759 49.0489L18.9228 49.0825H43.077L43.5239 49.0489ZM33.5833 33.5825V43.9159H28.4166V33.5825H20.6666L30.9999 20.6659L41.3332 33.5825H33.5833Z"
                          fill="#525298"
                        />
                      </g>
                    </svg>

                    <div className="ms-3">
                      <h5 className="primary_color f-14">
                        Scan or Upload: Front of card
                      </h5>
                      <p className="mb-0 text-muted f-12">Max file size 50mb</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div
                      className="alert alert-success d-flex align-items-center"
                      role="alert"
                    >
                      <svg
                        width="42"
                        height="46"
                        viewBox="0 0 42 46"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.6"
                          d="M41.25 16.25V43.2342C41.2521 43.5297 41.1959 43.8227 41.0848 44.0965C40.9736 44.3703 40.8096 44.6195 40.6021 44.8299C40.3947 45.0403 40.1478 45.2077 39.8756 45.3227C39.6034 45.4377 39.3112 45.4979 39.0158 45.5H2.98425C2.39208 45.5 1.82413 45.2649 1.40519 44.8464C0.986253 44.4279 0.750596 43.8602 0.75 43.268V2.732C0.75 1.52375 1.75575 0.5 2.9955 0.5H25.5V14C25.5 14.5967 25.7371 15.169 26.159 15.591C26.581 16.0129 27.1533 16.25 27.75 16.25H41.25ZM41.25 11.75H30V0.50675L41.25 11.75Z"
                          fill="#079D28"
                        />
                      </svg>
                      <div className="ms-3">
                        <h5 className="f-14">Front: Scan JPG</h5>
                        <p className="mb-0 f-12">File size 46 mb</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  <div className="">
                    <h5 className="primary_color f-14">Utility Bills</h5>
                    <h6 className="text-muted f-12">
                      Electricity Bills, House Rent Receipt
                    </h6>
                  </div>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body f-12">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Exercitationem accusantium repudiandae dicta suscipit enim
                  quam labore esse, officia, sunt ratione doloremque id iste
                  ducimus fuga illum error? Totam, aliquam veniam.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-4 mt-5">
            <ButtonComponent
              props={{
                anchor: "Update Profile",
                style: "onboarding_btn w-100 text-uppercase f-14",
              }}
            />
          </div>
        </div>
      </Profile>
    </>
  );
};

export default Document;
