import Transactions from "../transaction";
import TableComponent from "../../../../shared/components/tableComponent";

const WalletTransactions = () => {
  const data = [
    {
      id: "1000",
      status: "success",
      transaction_type: "transfer",
      reference: "yru790e",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1001",
      status: "warning",
      reference: "yru790e",
      transaction_type: "Withdrawal",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1002",
      status: "danger",
      reference: "yru790e",
      transaction_type: "transfer",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1000",
      status: "success",
      transaction_type: "transfer",
      reference: "yru790e",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1001",
      status: "warning",
      reference: "yru790e",
      transaction_type: "Withdrawal",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1002",
      status: "danger",
      reference: "yru790e",
      transaction_type: "transfer",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
    {
      id: "1000",
      status: "success",
      transaction_type: "transfer",
      reference: "yru790e",
      transfer_method: "card transfer",
      amount: "₦3,041,730",
      date: "19 July  4:30 PM",
    },
  ];
  return (
    <Transactions>
      <div className="table-responsive">
        <TableComponent props={data} />
      </div>
    </Transactions>
  );
};

export default WalletTransactions;
