import { NavLink } from "react-router-dom";
import { avatar } from "../../../assets/images/imageComponents";
import IconsComponent from "../../../assets/icons/iconsComponent";

const UserComponent = ({ children }) => {
  return (
    <div className="p-lg-4 w-100 vh-100 bg-white border-start remove_border">
      <div className="d-flex align-items-center">
        <div>
          <IconsComponent props="notification-icon" />
        </div>
        <NavLink className="text-decoration-none" to="/profile/general">
          <div className="d-flex align-items-center">
            <img className="mx-3" src={avatar} alt="" />
            <div className="">
              <h6 className="mb-1">
                Christopher
                <span className="xs_hide sm_hide md_hide">Aniedi</span>
              </h6>
              <h6 className="mb-0 lg_hide xl_hide">Aniedi</h6>
              <a
                className="text-muted text-decoration-none xs_hide sm_hide md_hide"
                href="mailto:chris@swiftwaze.com"
              >
                chris@swiftwaze.com
              </a>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="col-12">{children}</div>
    </div>
  );
};

export default UserComponent;
