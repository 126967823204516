import Carousel from "./carousel/carousel";

const Testimonial = () => {
  return (
    <div className="testimonial_section">
      <div className="container">
        <h6 className="testimonial_sub_header">Testimonial</h6>
        <h3 className="col-lg-6 col-xl-5 my-4 testimonial_header">Why people would love Swiftwaze.</h3>
        <Carousel type="content" />
      </div>
    </div>
  );
};

export default Testimonial;
