import { NavLink } from "react-router-dom";
import IconsComponent from "../../../assets/icons/iconsComponent";
import { logo } from "../../../assets/images/imageComponents";

const Footer = () => {
  return (
    <>
      <div className="container">
        <div className="row footer_margin">
          <div className="col-md-6 col-lg-4 col-xl-4 mb-4">
            <NavLink className="text-decoration-none" to="/">
              <div className="d-flex align-items-center">
                <img src={logo} alt="" />
                <h1 className="logo_text mb-0 ms-2">Swiftwaze</h1>
              </div>
            </NavLink>
            <p className="mt-4 home_text">
              Swiftways to move and manage your money within Africa and across borders with a smile.
            </p>
          </div>
          <div className="col-md-6 col-lg-2 col-xl-2 mb-4">
            <h5 className="footer_nav mt-3">Quick Links</h5>
            <ul className=" list-unstyled">
              <li className="mt-3">
                <a href="/" className="text-decoration-none home_text">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-decoration-none home_text">
                  About Us
                </a>
              </li>
              <li>
                <a href="/contact" className="text-decoration-none home_text">
                  Contact
                </a>
              </li>
              {/* <li>
                <a href="/blog" className="text-decoration-none home_text">
                  Blog
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 mb-4">
            <h5 className="footer_nav mt-3">Contact us</h5>
            <p className="mt-3 home_text mb-2">
              Lagos, Nigeria <br />
              San Francisco, USA. <br /> <br />
              +1(929)417-9726 <br /> support@swiftwaze.com <br />
              partnerships@swiftwaze.com
            </p>
          </div>
          <div className="col-md-6 col-lg-3 col-xl-3 mb-4 d-flex align-items-end">
            <div className="d-flex align-items-center">
              <a href="https://facebook.com/Swiftwaze" target="_blank" rel="noopener noreferrer">
                <IconsComponent props="facebook" />
              </a>
              <a href="https://twitter.com/swiftwaze" target="_blank" rel="noopener noreferrer">
                <IconsComponent props="twitter" />
              </a>
              <a href="https://www.linkedin.com/company/swiftwaze/" target="_blank" rel="noopener noreferrer">
                <IconsComponent props="linkedin" />
              </a>
              <a href="https://www.instagram.com/swiftwaze/" target="_blank" rel="noopener noreferrer">
                <IconsComponent props="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 border-top py-4">
        <div className="d-flex align-items-center justify-content-center">
          <p className="home_text mb-0">Copyright ® 2022 All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
