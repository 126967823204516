import { useState } from "react";
import { Select, Radio } from "antd";
import OtpInput from "react-otp-input";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import TransferSuccess from "../transferMoney/transferSuccess";
import SavedCard from "../transferMoney/savedCard";
import CardSection from "../transferMoney/cardSection";
import TransferSection from "../../../../shared/components/transferSection";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { Option } = Select;

const TopUp = () => {
  const [otp, setOtp] = useState("");
  const [card, setCard] = useState("new card");
  const [airtimeScreen, setAirtimeScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const handleOTP = (otp) => setOtp(otp);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setCard(e.target.value);
  };

  const handleOtpScreen = (e) => {
    e.preventDefault();
    setAirtimeScreen(!airtimeScreen);
    setOtpScreen(!otpScreen);
  };

  const handleSuccessScreen = (e) => {
    e.preventDefault();
    setOtpScreen(!otpScreen);
    setSuccessScreen(!successScreen);
  };

  return (
    <div className="container">
      {airtimeScreen && (
        <>
          <h3 className="drawer_header">Top Up</h3>
          <p className="drawer_subheader">
            To make a transfer provide the detials below
          </p>
          <div className="mt-5">
            <Select defaultValue="lucy" onChange={handleChange}>
              <Option value="jack">NGN Wallet Balance</Option>
              <Option value="lucy">Dollar Wallet Balance</Option>
            </Select>
            <h3 className="mt-2 balance_header">₦ 3,041,730</h3>
            <form action="" className="row">
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Amount",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <h3 className="mt-2 sidebar_label mt-5">Select funding source</h3>
              <div className="col-12 mt-3">
                <Radio.Group
                  className="d-flex"
                  onChange={onChange}
                  value={card}
                >
                  <Radio value="new card">New Card</Radio>
                  <Radio value="saved card">Saved Card</Radio>
                  <Radio value="transfer">Transfer</Radio>
                </Radio.Group>
              </div>
              {card === "new card" && (
                <>
                  <div className="my-4">
                    <FloatInputField
                      props={{
                        label: "Name on Card",
                        type: "text",
                        style: "w-100 input_style rounded border",
                      }}
                    />
                  </div>
                  <CardSection />
                </>
              )}
              {card === "transfer" && (
                <>
                  <TransferSection />
                </>
              )}
              {card === "saved card" && (
                <>
                  {[1, 2].map((card, i) => (
                    <div className="col-12 mt-4" key={i}>
                      <SavedCard />
                    </div>
                  ))}
                </>
              )}
              <div className="col-12 mt-4">
                <ButtonComponent
                  props={{
                    anchor: "top up",
                    style: "onboarding_btn w-100 text-uppercase",
                    handleClick: handleOtpScreen,
                  }}
                />
              </div>
            </form>
          </div>
        </>
      )}
      {otpScreen && (
        <>
          <h3 className="drawer_header">Transaction Pin</h3>
          <p className="drawer_subheader">
            Provide transaction to fund wallet from card
          </p>
          <div className="mt-5">
            <form action="" className="row">
              <div className="col-12 mt-5">
                <p className="otp_text">Enter your 4 digit pin</p>
                <div className="mb-5 col-12 d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleOTP}
                    numInputs={4}
                    inputStyle="otp_style"
                  />
                </div>
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "Transfer",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleSuccessScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {successScreen && (
        <>
          <TransferSuccess />
        </>
      )}
    </div>
  );
};

export default TopUp;
