import { avatar } from "../../../../assets/images/imageComponents";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import Profile from "../profile";

const GeneralProfile = () => {
  return (
    <>
      <Profile>
        <div className="col-lg-10 col-xl-8 p-lg-5 pt-lg-4 general_section">
          <div className="row">
            <div className="col-md-4">
              <h6>Your Photo</h6>
            </div>
            <div className="col-md-8 text-center">
              <img height={120} src={avatar} alt="" /> <br />
              <button className="mt-3 upload_btn border-0 bg-transparent">
                Upload Image
              </button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Full Name</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "Enter your full name",
                  type: "text",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Email Address</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "Enter your email address",
                  type: "text",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Phone Number</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "Enter your phone number",
                  type: "number",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Address</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "Enter your address",
                  type: "text",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center"></div>
            <div className="col-md-8">
              <ButtonComponent
                props={{
                  anchor: "Update Profile",
                  style: "onboarding_btn w-100 text-uppercase f-14",
                }}
              />
            </div>
          </div>
        </div>
      </Profile>
    </>
  );
};

export default GeneralProfile;
