import { useLocation, useNavigate } from "react-router-dom";
import { bank_icon } from "../../../assets/images/imageComponents";
import ButtonComponent from "../../../shared/components/buttonComponent";
import FloatInputField from "../../../shared/components/floatInputField";
import TransactionUserProfile from "../../../shared/components/userProfile/transactions/transactionsUserProfile";
import UserComponent from "../../../shared/components/userProfile/userComponent";
import DashboardLayout from "../../../shared/layouts/dashboardLayout";

const Transactions = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <DashboardLayout>
        <div className="scroll_none">
          <div className="row">
            <div className="col-lg-8 col-xl-9 scroll_y vh-100">
              <div className="container-fluid">
                <div className="py-4">
                  <div className="d-flex block justify-content-between">
                    <h3 className="dashboard_header">Transactions</h3>
                    <FloatInputField
                      props={{
                        label: "Search",
                        type: "search",
                        style: "w-100 input_style rounded shadow",
                      }}
                    />
                  </div>

                  <div className="mt-5 d-flex justify-content-between mb-4">
                    <div className="d-flex align-items-center block">
                      <button
                        onClick={() => navigate("/transactions/wallet")}
                        className={`bg-transparent border-0 me-3 ${
                          location.pathname === "/transactions/wallet" &&
                          "active_tab"
                        }`}
                      >
                        Wallet Transactions
                      </button>
                      <button
                        onClick={() => navigate("/transactions/card")}
                        className={`bg-transparent border-0 ${
                          location.pathname === "/transactions/card" &&
                          "active_tab"
                        }`}
                      >
                        Card Transactions
                      </button>
                    </div>
                    <div className="btn-group">
                      <button
                        type="button"
                        className="bg-white border px-4 py-2 dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Duration
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <button className="dropdown-item" type="button">
                            Daily
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            Monthly
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 xs_hide sm_hide md_hide ps-0 scroll_y vh-100">
              <UserComponent>
                <TransactionUserProfile />
              </UserComponent>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Transactions;
