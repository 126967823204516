import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { axiosConfig, url } from "../../shared/config";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import axios from "axios";
import OnboardingLayout from "../../shared/layouts/onboardingLayout";
import * as yup from "yup";

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation((formData) => {
    return axios.post(`${url}/login`, formData, axiosConfig);
  });

  const navigate = useNavigate();
  const schema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .max(300),
    pword: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const data = await mutation.mutateAsync(formData);
      data.data.token &&
        localStorage.setItem("swiftwaze_token", data.data.token);
      data.data.status && navigate("/dashboard");
      await enqueueSnackbar(data.data.msg, {
        variant: data.data.status ? "success" : "error",
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <OnboardingLayout>
      <div className="onboarding_scroll_y">
        <h3 className="fw-bold">Welcome Back</h3>
        <p className="home_text text-muted">Login to your account</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="w-100 onboarding_field shadow-sm"
              {...register("email")}
            />
            <p className="mt-2 text-danger f-12">{errors.email?.message}</p>
          </div>
          <div className="col-12">
            <label htmlFor="pword">Password</label>
            <input
              type="password"
              className="w-100 onboarding_field shadow-sm"
              {...register("pword")}
            />
            <p className="mt-2 text-danger f-12">{errors.pword?.message}</p>
          </div>
          <button className="primary_btn mt-4">
            {mutation.isLoading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-3"
              />
            )}
            Sign In
          </button>
        </form>
        <div className="d-flex justify-content-center mt-4">
          <NavLink to="/forgot-password">Forgot Password</NavLink>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default SignIn;
