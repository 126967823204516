const TableComponent = ({ props }) => {
  return (
    <div>
      <div className="table-responsive">
        <table className="table">
          <thead className="">
            <tr>
              <th scope="col">Reference</th>
              <th scope="col">Transaction </th>
              <th scope="col">Transfer Method</th>
              <th scope="col">Amount</th>
              <th scope="col">Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {props.map((data) => (
              <tr key={data.id}>
                <td>{data.reference}</td>
                <td>{data.transaction_type}</td>
                <td>{data.transfer_method}</td>
                <td>{data.amount}</td>
                <td>{data.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
