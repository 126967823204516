const ButtonComponent = ({ props }) => {
  return (
    <button
      className={props.style && props.style}
      onClick={props.handleClick && props.handleClick}
    >
      {props.anchor}
    </button>
  );
};

export default ButtonComponent;
