import { blog_banner } from "../../../assets/images/imageComponents";
import BlogPost from "../../../shared/components/blogPost";
import ButtonComponent from "../../../shared/components/buttonComponent";
import Subscription from "../../../shared/components/subscription";
import WebsiteLayout from "../../../shared/layouts/websiteLayout";

const BlogDetail = () => {
  return (
    <WebsiteLayout>
      <div className="container">
        <h2 className="my-4 col-lg-7">Lorem Ipsum is simply dummy text of the printing.</h2>
      </div>
      <div className="wrapper">
        <img className="w-100" src={blog_banner} alt="" />
        <div className="row section_margin mt-5">
          <div className="col-12">
            <div className="container">
              <div className="row my-5">
                <div className="col-md-6">
                  <p className="home_text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.{" "}
                    <br /> <br /> Why do we use it? It is a long established
                    fact that a reader will be distracted by the readable
                    content of a page when looking at its layout. The point of
                    using Lorem Ipsum is that it has a more-or-less normal
                    distribution of letters, as opposed to using 'Content here,
                    content here', making it look like readable English. Many
                    desktop publishing packages
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="home_text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.{" "}
                    <br /> <br /> Why do we use it? It is a long established
                    fact that a reader will be distracted by the readable
                    content of a page when looking at its layout. The point of
                    using Lorem Ipsum is that it has a more-or-less normal
                    distribution of letters, as opposed to using 'Content here,
                    content here', making it look like readable English. Many
                    desktop publishing packages
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 border-top">
            <div className="container">
              <div className="d-flex align-items-center my-5 justify-content-between">
                <h3 className="mb-0">Related Articles</h3>
                <ButtonComponent
                  props={{ anchor: "Read More", style: "read_more btn" }}
                />
              </div>
              <div className="row">
                {[1, 2, 3].map((post, i) => (
                  <div className="col-md-4 mb-4">
                    <BlogPost id={i} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscription />
    </WebsiteLayout>
  );
};

export default BlogDetail;
