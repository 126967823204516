import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { axiosConfig, url } from "../../../shared/config";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import axios from "axios";
import OnboardingLayout from "../../../shared/layouts/onboardingLayout";
import * as yup from "yup";

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation((formData) => {
    return axios.post(`${url}/login`, formData, axiosConfig);
  });

  const navigate = useNavigate();
  const schema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .max(300),
    pword: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const data = await mutation.mutateAsync(formData);
      data.data.token &&
        localStorage.setItem("swiftwaze_token", data.data.token);
      data.data.status && navigate("/dashboard");
      await enqueueSnackbar(data.data.msg, {
        variant: data.data.status ? "success" : "error",
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <OnboardingLayout>
      <div className="onboarding_scroll_y d-flex align-items-center">
        <div className="col-12">
          <h3 className="fw-bold mb-4">Forgot Password</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <label htmlFor="email">Enter your email address</label>
              <input
                type="email"
                className="w-100 onboarding_field shadow-sm"
                {...register("email")}
              />
              <p className="mt-2 text-danger f-12">{errors.email?.message}</p>
            </div>
            <button
              className="primary_btn mt-4"
              onClick={navigate("/password-reset")}
            >
              {mutation.isLoading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-3"
                />
              )}
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
