import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";
import About from "../../pages/about/about";
import Dashboard from "../../pages/app/dashboard/dashboard";
import Document from "../../pages/app/profile/document/document";
import GeneralProfile from "../../pages/app/profile/general/general";
import Help from "../../pages/app/profile/help/help";
import Privacy from "../../pages/app/profile/privacy/privacy";
import Security from "../../pages/app/profile/security/security";
import CardTransactions from "../../pages/app/transaction/card-transaction/cardTransaction";
import WalletTransactions from "../../pages/app/transaction/wallet-transactions/walletTransaction";
import Wallet from "../../pages/app/wallet/wallet";
import Blog from "../../pages/blog/blog";
import BlogDetail from "../../pages/blog/blog-detail/detail";
import Homepage from "../../pages/homepage/homepage";
import ForgotPassword from "../../pages/onboarding/passwordReset/forgot-password";
import PasswordReset from "../../pages/onboarding/passwordReset/resetPassword";
import ChangePassword from "../../pages/onboarding/passwordReset/changePassword";
import SignIn from "../../pages/onboarding/sign-in";
import SignUp from "../../pages/onboarding/sign-up";
import Support from "../../pages/support/support";
import WhySwiftwaze from "../../pages/why-swiftwaze/why-swiftwaze";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/why-swiftwaze" element={<WhySwiftwaze />}></Route>
        <Route path="/contact" element={<Support />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/blog/:id" element={<BlogDetail />}></Route>
        <Route path="/sign-in" element={<SignIn />}></Route>
        <Route path="/sign-up" element={<SignUp />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/password-reset" element={<PasswordReset />}></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route
          path="/transactions/wallet"
          element={<WalletTransactions />}
        ></Route>
        <Route path="/transactions/card" element={<CardTransactions />}></Route>
        <Route path="/wallet" element={<Wallet />}></Route>
        <Route path="/profile/general" element={<GeneralProfile />}></Route>
        <Route path="/profile/security" element={<Security />}></Route>
        <Route path="/profile/help" element={<Help />}></Route>
        <Route path="/profile/privacy" element={<Privacy />}></Route>
        <Route path="/profile/documents" element={<Document />}></Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
